.contact-section {
    min-height: 100vh;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.title-container {
    align-self: flex-start;
    margin-left: var(--margin);
}

.contact-section__container {
    background-color: var(--green);
    width: min(500px, 90vw);
    height: min(500px, 90vw);
    padding: 5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    & p {
        color: var(--vanilla);
        font-size: 1.5rem;
    }

    & label {
        color: var(--vanilla);
    }

    & .btn {
        background-color: var(--vanilla);
        color: var(--green);

        &:hover {
            background-color: var(--pink);
        }
    }

    & .label-float input,
     .label-float textarea {
        color: var(--vanilla);
    }

    & .label-float textarea,
    .label-float input {
        border-color: var(--yellow) !important;
    }
    
    & .label-float input:focus~label,
    .label-float textarea:focus~label,
    label-float textarea:not(:placeholder-shown)~label,
    .label-float input:not(:placeholder-shown)~label {
        color: var(--vanilla) !important;
    }
    
    & .success-message h2, .success-message p {
        color: var(--vanilla) !important;
    }
}

@media (max-width: 768px) {
    .contact-section {
        background-color: var(--green);   
    }

    .title-container {
        align-self: center;
        margin-left: 0;
        display: none;
    }
}