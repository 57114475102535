.colab-container {
    width: min(900px, 90vw);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 6rem;
}

.colab-text {
    font-size: 1.2rem;
    color: var(--green);
}

.colab-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    /* background-color: aqua; */
    /* width: min(500px, 90%); */
}

.label-float {
    position: relative;
    padding-top: 15px;
}

.label-float input:not(:placeholder-shown),
.label-float textarea:not(:placeholder-shown) {
    padding-top: 0px;
}

.label-float textarea:not(:placeholder-shown)~label,
.label-float input:not(:placeholder-shown)~label {
    font-size: 0.8rem;
    top: 0;
    color: var(--space-grey);
}

.label-float label {
    position: absolute;
    top: 15px;
    left: 0;
    transition: all 0.3s ease;
    pointer-events: none;
}

.label-float input:focus~label,
.label-float textarea:focus~label {
    font-size: .8rem;
    top: 0;
    color: var(--green);
}

.area label {
    top: 20px;
    left: 5px;
}

/* .area textarea:focus~label {
    top: 20px;
    color: var(--green);
} */

.label-float input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid var(--space-grey);
    display: block;
    width: 100%;
    font-size: 1rem;
    font-variation-settings: 'wght' 97;
    color: var(--green);
    box-sizing: border-box;
}

.label-float textarea {
    box-sizing: border-box;
    background-color: transparent;
    border-radius: 5px;
}

.label-float textarea:focus {
    outline: none;
    border: 2px solid var(--green);
}

.label-float input:focus {
    outline: none;
    border-bottom: 2px solid var(--green);
}

.label-float textarea {
    resize: none;
    width: 100%;
    height: 100px;
    font-size: 1rem;
    font-variation-settings: 'wght' 97;
    color: var(--green);
}