.giveback {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    /* margin: var(--margin); */
}

#logos-circle {
    transform-origin: center;
}

.giveback-image {
    height: 80%;
    width: auto;
}

@media (prefers-reduced-motion: no-preference) {
    #logos-circle {
        animation: circle-spin infinite 20s linear;
    }
}

@keyframes circle-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 768px) {
    .giveback-image {
        width: 90vw;
        height: auto;
    }
}