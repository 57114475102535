.manifest-tool {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.manifest-container-tool {
    box-sizing: border-box;
}

.manifest-racket {
    height: 100vh;
    width: auto;   
}

@media (max-width: 768px) {
    .manifest-racket {
        width: 90vw;
        height: auto;   
    }
}

