.font-card {
    width: 250px;
    width: min(500px, 100%);
    height: min(500px, 100vw);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: relative;
    transform-style: preserve-3d;
}

.font-card__image {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    position: absolute;
}

.font-card__image::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--yellow);
    opacity: 0.5;
    mix-blend-mode: multiply;
    z-index: 1;
}

.font-card__image img {
    height: 100%;
    filter: grayscale(100%);
}

.font-card__info-container {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    box-sizing: border-box;
}

.font-card__name,
.font-card__status,
.font-card__styles,
.font-card__version,
.font-card__price {
    width: fit-content;
    height: fit-content;
    background-color: var(--vanilla);
    color: var(--green);
    padding: .2rem .4rem;
    margin-bottom: .5rem;

    & span {
        font-variation-settings: 'wght' 147;
    }
}

.font-card__name {
    font-family: 'Leluja', serif;
    font-style: Regular;
    font-size: 3rem;
    background-color: var(--green);
    color: var(--vanilla);
}

.font-card__button-container {
    display: flex;
    justify-content: space-between;
}

.font-card__plus-button {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.font-card__plus-button svg {
    width: 40px;
    height: 40px;
}

.font-card__plus-button:hover {
    transform: scale(1.1);
}

.font-card__buy-button {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: none;
    background-color: var(--green);
    color: var(--vanilla);
    padding: .5rem 1rem;
    border-radius: 8px;
    height: fit-content;
}

.font-card__buy-button:hover {
    transform: scale(1.1);
}