:root {
  --green: #015958;
  --light-green: #E2E08D;
  --yellow: #ffdd44;
  --vanilla: #f0efdd;
  --pink: #f37f93;
  --light-pink: #f8cdcc;
  --space-grey: #65737e;
  --dark-green: #0f2f2f;
  --padding: 50px;
  --base-unit: 50px;
  --logo-size: 150px;
  --margin: calc(var(--padding) + var(--logo-size));
}


@font-face {
  font-family: 'Ar Sans';
  src: url('../public/fonts/ArSansVF.woff2') format('woff2-variations'),
    url('../public/fonts/ArSansVF.woff2') format('woff2');
}

@font-face {
  font-family: 'Leluja';
  src: url('../public/fonts/LelujaOriginal-Regular.woff2') format('woff2');
  font-weight: 'Regular';
}

@font-face {
  font-family: 'Leluja';
  src: url('../public/fonts/LelujaOriginal-Bold.woff2') format('woff2');
  font-weight: 'Bold';
}

@font-face {
  font-family: 'Leluja';
  src: url('../public/fonts/LelujaOriginal-Italic.woff2') format('woff2');
  font-weight: 'Italic';
}

@font-face {
  font-family: 'Leluja';
  src: url('../public/fonts/LelujaOriginal-BoldItalic.woff2') format('woff2');
  font-weight: 'Bold Italic';
}

html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 16px;
  font-family: 'Ar Sans', sans-serif;
  font-variation-settings: 'wght' 97;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--vanilla);
  width: 100%;
  scroll-behavior: smooth;
}

body {
  font-family: 'Ar Sans', sans-serif;
  font-variation-settings: 'wght' 97;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--vanilla);
  width: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  /* position: relative; */
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: var(--green);
}

h1, h2, h3 {
  font-variation-settings: 'wght' 100;
  color: var(--green);
  margin-bottom: 2rem;
}

.section {
  margin: var(--logo-size) var(--margin);
}

.section__title {
  font-size: 1.5rem;
  font-variation-settings: 'wght' 50;
  color: var(--green);
  text-transform: lowercase;
  width: fit-content;
  margin-bottom: 4rem;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: var(--green);
    /* margin-top: 0.5rem; */
  }
}

.btn {
  border: none;
  background-color: var(--green);
  border-radius: 5px;
  padding: 0.25rem 0;
  font-variation-settings: 'wght' 97;
  color: var(--vanilla);
  width: min(150px, 100%);
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn:hover {
  background-color: var(--pink);
}

@media (max-width: 768px) {
  .section {
    margin: var(--padding);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .section__title {
    text-align: center;
  }
}