.manifest-section {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.manifest-container {
    box-sizing: border-box;
    background-color: var(--green);
    margin: var(--margin);
}

.manifest-sentence {
    padding: 4%;
}

.manifest-p {
    font-size: clamp(2rem, 0.571rem + 3.81vw, 4rem);
    color: var(--vanilla);
    font-variation-settings: 'wght' 200;
    text-align: center;
}

.manifest-technical {
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    background-attachment: fixed;
}

@media (max-width: 768px) {
    .manifest-technical {
        background-repeat: repeat;
        background-size: 100%;
        background-position: 0 0;
        background-attachment: fixed;
    }

    .manifest-container {
        margin: var(--padding);
    }

    .manifest-p {
        font-size: 1.7rem;
    }
}

