.hero {
  position: absolute;
  top: var(--padding);
  bottom: var(--padding);
  width: 100%;
  z-index: 0;
}

.carrousel-container {
  position: relative; 
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.slide {
  /* display: none; */
  /* Esconde todos os slides por padrão */
  height: 100%;
  width: 100%;
  object-fit: fill;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.slide.active {
  display: block;
  z-index: 1;
  /* Mostra o slide ativo */
}

.hero .carrousel-container .slide img {
  width: 100%;
  /* Faz a imagem cobrir toda a largura do container */
  height: 100%;
  /* Faz a imagem cobrir toda a altura do container */
  object-fit: cover;
  /* Garante que a imagem cubra o espaço sem perder as proporções */
}

.indicators {
  position: absolute;
  bottom: calc(var(--padding) / 2);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: 1rem;
  z-index: 3;
}

.indicator {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid var(--green);
  /* background-color: var(--green); */
  display: inline-block;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.indicator.active {
  background-color: var(--yellow);
  scale: 1.3;
}