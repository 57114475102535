.error-message,
.success-message {
    animation: 1s ease-out 0s 1 slideInFromBottom;
}

.error-message h2,
.success-message h2 {
    font-size: 2rem;
    color: var(--green);
    margin: 0;
    padding: 0;
    text-align: center;
}

.error-message p,
.success-message p {
    font-size: 1.2rem;
    color: var(--space-grey);
    margin: 0;
    padding: 0;
    text-align: center;
}

@keyframes slideInFromBottom {
    0% {
        opacity: 0;
        transform: translateY(100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* <div class="success-message"><h2>Email enviado com sucesso !</h2><p>Entraremos em contato em breve.</p></div> */