.opening {
  position: relative;
  width: 100vw;
  height: 100vh;
}

.horizontal-line {
  width: 100%;
  height: 3px;
  background-color: var(--green);
  position: fixed;
  z-index: 1;
}

  #line1 {
    top: var(--padding);
  }

  #line2 {
    bottom: var(--padding);
  }

#svg-logo {
  width: 150px;
  height: 150px;
  display: block;
  z-index: 10;
  position: absolute;
  left: calc(var(--padding)/2);
  top: 50%;
  transform: translateY(-50%);
}


.main-content {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: var(--green); */
  z-index: 2;
  padding-inline: calc(var(--padding)/2);
}

.content {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 768px) {
  #svg-logo {
    display: none;
  }

  .logo-mobile {
    display: block;
    margin-top: 5px;
  }

  .logo-mobile img {
    height: 30px;
    width: auto;
  }

  #line1, #line2 {
    display: none;
  }
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
