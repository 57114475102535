.colab {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* .colab-text {
    width: min(500px, 90%);
} */