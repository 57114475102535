.header {
    width: 100vw;
    height: var(--base-unit);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: var(--padding);
    padding-inline: calc(var(--padding) / 2);
    box-sizing: border-box;
}

.menu {
    display: flex;
    gap: 1rem;
    align-items: center;
    width: fit-content;
    /* height: 100%; */
}

.menu_item {
    cursor: pointer;
    line-height: 1rem;
    transition: all 0.3s ease;
}

.menu_item:hover {
    font-variation-settings: 'wght' 147;
}

.instagram-icon {
    width: 25px;
    height: 25px;
    transition: all 0.3s ease;
}

.instagram-icon:hover {
    transform: scale(1.1);
}

.icon-container {
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;
    /* z-index: 99; */
    height: 55%;
    width: auto;
    display: block;
    z-index: 100; 
}

.icon-container:hover {
    transform: scale(1.1);
}

#close-icon,
#bag-icon {
    height: 100%;
    width: auto;
}

.icon-path,
.bag-path {
    stroke: var(--green);
}

.side-cart {
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
    height: 100vh;
    background-color: var(--yellow);
    z-index: 99;
    padding: var(--padding);
    box-sizing: border-box;
    transform: translateX(100%);
    transition: all 0.3s ease;
}

.side-cart.open {
    transform: translateX(0);
}

.hamburger-react,
.menu_mobile,
.mobile-item {
    display: none;
}

@media screen and (max-width: 768px) {
    .menu {
        display: none;
    }

    .menu.open {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100vw;
        height: 50vh;
        left: 0;
        top: 0;
        background-color: var(--yellow);
        position: absolute;
        border-bottom: 2px solid var(--green);
        /* gap: 2rem; */
    }

    .menu_item {
        font-size: 1.5rem;
        font-weight: 400;
        margin: 0 20%;
        /* padding-bottom: 1rem; */
    }

    .hamburger-react {
        display: block;
    }

    .menu_mobile {
        display: flex;
        gap: 1rem;
        align-items: center;
        width: fit-content;
    }
}