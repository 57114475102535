.font-section {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
}

.cards-container {
    display: flex;
    width: min(1200px, 100%);
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
}