.team-member-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.team-member {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.team-member__image {
    width: 400px;
    height: 400px;
    /* height: 400px; */
    border-radius: 50%;
    overflow: hidden;
    position: relative;

    & img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;

        &.raquete {
            width: 50%;
            height: auto;
            position: absolute;
            z-index: 1;
        }
    }
}

.raquete--esquerda {
    top: 50%;
}

.raquete--direita {
    top: 50%;
    right: 0;
    width: 40% !important;
    height: auto;
}

.team-member__name {
    font-family: 'Leluja', sans-serif;
    font-size: 3rem;
    color: var(--green);
}

.team-member__bio {
    width: min(500px, 100%);
}

.team-member__bio__text {
    font-size: 0.8rem;
    color: var(--green)
}

.filipe {
    align-items: flex-end;

    & .team-member__image {
        order: 1;
    }

    & .team-member__bio__text {
        text-align: right;
    }
}

@media screen and (max-width: 1200px) {
    .team-member-container {
        flex-direction: column;
        gap: 4rem;
    }

    .team-member__name {
        text-align: left;
    }

    .filipe {
        align-items: flex-start;

        & .team-member__image {
            order: 0;
        }

        & .team-member__bio__text {
            text-align: left;
        }
    }
}

@media screen and (max-width: 768px) {
    .team-member__image {
        width: 300px;
        height: 300px;
    }

    .team-member__name {
        text-align: center;
    }

    .filipe {
        align-items: center; 
    }
}